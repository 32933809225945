import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <FontAwesomeIcon icon={solid('person-digging')} />
        <p>
          Under construction
        </p>
      </header>
    </div>
  );
}

export default App;
